@import '~normalize.css';
@import '~react-table/react-table.css';
@import '~create-redux-form/animations/carousel.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
  font-family: 'Red Hat Display', sans-serif !important;
  background-color: #1D1F23 !important;
  color: #fff !important;
}
